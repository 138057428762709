export const rootHref = '/';
export const aboutHref = '/about';
export const contactHref = '/contact';
export const toolsHref = '/tools';
export const mapsHref = '/tools/maps';
export const dataHref = '/tools/data';

/** Shows the tax per acre Tableau dashboard. */
export const toolTaxPerAcreHref = `${dataHref}/tax-per-acre`;
export const toolMapHref = '/tools/map';

export const legalAttributionsHref = '/attributions';
export const legalPrivacyPolicyHref = '/privacy-policy';
export const legalTermsConditionsHref = '/terms-conditions';

export const rapidResponseHref = '/get-involved/rapid-response';
export const villagesHref = '/get-involved/villages';
export const marketingTeamHref = 'https://joinmarketing.strongtownsphx.org';
export const leadershipHref = 'https://blog.strongtownsphx.org/team/';
export const getInvolvedHref = 'https://getinvolved.strongtownsphx.org';
export const podcastHref = 'https://podcast.strongtownsphx.org';

export const initiativesHref = '/initiatives';
export const steeleIndianSchoolParkHref = `${initiativesHref}/steele-indian-school-park`;

export const blogHref = '/blog';
export const mesaLightRailExpansionHref = `${blogHref}/mesa-light-rail-expansion`;
export const inauguralBlogHref = `${blogHref}/launching-our-blog`;

export const nextMeetingHref = '/resources/next-meeting';
export const upcomingEventsHref = '/resources/upcoming-events';
export const pastEventsHref = '/resources/past-events';
export const meetingNotesHref = '/resources/meeting-notes';
export const mediaKitHref = '/resources/press-kit';

export const merchandiseHref = '/merchandise';

export const crispHref = 'https://indianschoolpark.org';
export const globalBlogHref = 'https://blog.strongtownsphx.org';
export const globalBlogSignupHref = `${globalBlogHref}/#/portal/signup`;
export const globalBlogEventsHref = `${globalBlogHref}/tag/events`;
export const globalBlogMeetingNotesHref = `${globalBlogHref}/tag/meeting-notes`;
export const globalInitiativesHref = `${globalBlogHref}/initiatives`;
export const globalBlogContributeHref = `${globalBlogHref}/want-to-contribute`;
export const globalBlogBuildItTheyWillComeHref = `${globalBlogHref}/if-you-build-it-they-will-come`;
