import React from 'react';
import { Link } from 'react-router-dom';
// import { useRouteError } from 'react-router-dom';
import {
  // contactHref,
  rootHref,
} from '../constants/constants';

const ComingSoonPage = () => {
  // const error = useRouteError();
  // {error.statusText || error.message}

  return (
    <>
      <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold text-blue-600">Content not available yet</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Coming soon!</h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            We&apos;re still ramping up and it takes some time, so you&apos;ll have to bear with us. This page is currently not ready yet. Stay tuned, it&apos;s coming soon!
          </p>
          <p className="mt-6 text-base leading-7 text-gray-600">
            If you want to help us get things up and running faster, reach out to us!
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link to={rootHref}
              className="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              Go back home
            </Link>
            {/* <Link to={contactHref} className="text-sm font-semibold text-gray-900">
              Contact support <span aria-hidden="true">&rarr;</span>
            </Link> */}
          </div>
        </div>
      </main>
    </>
  );
};


export default ComingSoonPage;
