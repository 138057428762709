import React from 'react';
// import { Link } from 'react-router-dom';

/**
 * A footer link is a simple <a href=...> element
 */
export interface FooterLink {
    text: string;
    href: string;
}

/**
 * An icon-only social link with corresponding screen reader-only text for
 * accessibility
 */
export interface SocialLink {
    text: string;
    link: string;
    svg: JSX.Element;
}

/**
 * Contains a vertical series of links to be displayed in a column
 */
export interface FooterLinkColumn {
    links: FooterLink[];
    headerText: string;
}

/**
 * Should be able to support an arbitrary number of columns, up to what
 * Tailwind supports, which I believe is 16?
 */
export interface FooterProps {
    linkColumns: FooterLinkColumn[];
    bottomLeftContent: JSX.Element;
    socials: SocialLink[];
}

const Footer = (props: FooterProps) => {
  const {
    linkColumns,
    bottomLeftContent,
    socials,
  } = props;

  const renderedColumnLinks = linkColumns.map((linkColumn, i) => {
    const renderedLinks = linkColumn.links.map((link, j) => {
      return (
        <li key={`footerlink-${i}-${j}`} className="mb-4">
          <a href={link.href} className="hover:underline">{link.text}</a>
        </li>
      );
    });

    return (
      <div key={`footercol-${i}`}>
        <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
          {linkColumn.headerText}
        </h2>
        <ul className="text-gray-500 dark:text-gray-400 font-medium">
          {renderedLinks}
        </ul>
      </div>
    );
  });

  const renderedSocials = socials.map((social, i) => {
    return (
      <a href={social.link} key={`social-link-${i}`}  className="text-gray-400 hover:text-gray-900 dark:hover:text-white" target="_blank" rel="noopener noreferer nofollow noreferrer">
        {social.svg}
        <span className="sr-only">{social.text}</span>
      </a>
    );
  });

  // Heads up: There is a really weird bug with Tailwind here. Sometimes, it
  // just misbehaves and only renders one column instead of N columns. I suspect
  // it's a build-time caching issue that doesn't seem to cooperate when using a
  // template string for the grid.
  //
  // To fix this, you have to:
  //
  // - npm run start
  // - replace `lg:grid-cols-${linkColumns.length}` with `lg:grid-cols-4` and
  //   save
  // - verify that 4 columns are shown in the footer in your browser
  // - undo the change you just did (add `lg:grid-cols-${linkColumns.length}`
  //   back)
  // - verify that the correct number of columns are shown in the footer as
  //   intended
  return (
    <footer className="bg-gray-100 dark:bg-gray-900">
      <div className="mx-auto w-full max-w-screen-xl">
        <div className={`grid lg:grid-cols-${linkColumns.length} gap-8 px-4 py-6 lg:py-8 sm:grid-cols-1`}>
          {renderedColumnLinks}
        </div>
      </div>
      <div className="px-8 py-6 bg-gray-200 dark:bg-gray-700 md:flex md:items-center md:justify-between">
        <span className="text-sm text-gray-500 dark:text-gray-300 sm:text-center">
          {bottomLeftContent}
        </span>
        <div className="flex mt-4 sm:justify-center md:mt-0 space-x-5 rtl:space-x-reverse">
          {renderedSocials}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
