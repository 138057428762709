import React from 'react';
import { TableauEmbed } from '@stoddabr/react-tableau-embed-live';
import { Element } from 'react-scroll';

/**
 * Displays Prakshal & Jeremy's "Tax Per Acre" tableau dashboard.
 *
 * @see https://public.tableau.com/app/profile/prakshal.jain4169/viz/tax_full/Dashboard?publish=yes
 * @see https://public.tableau.com/views/tax_full/Dashboard?:language=en-US&publish=yes&:sid=&:display_count=n&:origin=viz_share_link
 */
const TaxPerAcreTableau = () => {
  return (
    <div className="bg-white py-8 mx-8">
      <div className="block md:hidden bg-yellow-200 text-yellow-800 p-4">
        We recommend viewing this page on a wider screen.
      </div>
      <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Tax Per Acre Analysis</h1>

      <h3 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-2xl">What Is It?</h3>
      <p className="mt-2 text-base leading-7 text-gray-600">
        Tax per acre is a measure of the amount of tax levied on each acre of land, reflecting its assessed value and potential use.
      </p>
      <h3 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-2xl">Why Does It Matter?</h3>
      <p className="mt-2 text-base leading-7 text-gray-600">
        <ul className="list-disc ml-6">
          <li><strong>Economic Planning</strong>: Helps in budgeting and financial planning for both individuals and businesses.</li>
          <li><strong>Land Use Optimization</strong>: Encourages the most productive use of land, whether for agriculture, development, or conservation.</li>
          <li><strong>Equity and Fairness</strong>: Ensures fair distribution of tax burden based on land value and use.</li>
          <li><strong>Resource Allocation</strong>: Guides government and public sector investment in infrastructure and services.</li>
          <li><strong>Sustainable Development</strong>: Promotes responsible land stewardship and environmental conservation.</li>
        </ul>
        <br></br>
        For more detailed insights, visit <a target="_blank" rel="noreferrer" href="https://www.strongtowns.org/journal/2018/10/19/value-per-acre-analysis-a-how-to-for-beginners" className="text-blue-600 underline">Strong Towns Value Per Acre Analysis Blog</a>.
      </p>

      <div className="element mt-8">
        <h2 className="text-2xl font-semibold text-gray-800">Interactive Dashboard</h2>
        <p className="mt-4 text-base leading-7 text-gray-600">
          Start exploring to analyze tax per acre data, visualize trends, and gain insights into how different factors influence land taxation.
          <br></br>
          <strong>To get started, select your city from the dropdown!</strong>
        </p>

        <br></br>

        <Element name="tax-per-acre-dashboard" className="element">
          <TableauEmbed sourceUrl="https://public.tableau.com/views/tax_full/Dashboard" />
        </Element>

        <br></br>

        <h3 className="text-2xl font-semibold text-gray-800">Visualization Breakdown</h3>
        <ul className="mt-6 text-base leading-7 text-gray-600 list-disc ml-6">
          <li>
            <strong>Average Tax/Acre Map: </strong>
            This map visualizes the average tax per acre across different regions of the selected city. The color gradient indicates the percentile of average city tax per acre, with greener shades representing higher taxes per acre.
          </li>
          <li>
            <strong>Avg Tax/Acre by Property Type: </strong>
            The bar chart shows the average tax per acre for various property types such as Medical, Hotel/Motel, Multi-Family, Commercial, Industrial, Parking, SFR (Single-Family Residential), Other, and Vacant. This helps in understanding which property types generate the highest and lowest tax revenues per acre.
          </li>
          <li>
            <strong>Tax vs Acres Contribution: </strong>
            This section presents a stacked bar chart comparing the total tax contribution and the percentage of total acres for each property type. The chart highlights the proportion of tax revenue generated relative to the land area occupied by each property type.
          </li>
        </ul>
        <br></br>

        <h3 className="text-2xl font-semibold text-gray-800">Common Trends</h3>
        <ul className="mt-6 text-base leading-7 text-gray-600 list-disc ml-6">
          <li>
            <strong>Vacant</strong> lots contribute very little in proportion to the land they occupy.
            Watch this to learn more: <a target="_blank" rel="noreferrer nofollow noopener" href="https://www.youtube.com/watch?v=xqQhoZgFZgk" className="text-blue-600 underline">Thank You From a Land Speculator</a>.
          </li>
          <li><strong>Single Family Residences (SFR)</strong> tend to follow Vacant lots; disproportionate tax contribution in relation to land share.</li>
          <li><strong>Multi-Family</strong> is the opposite; more revenue generated from a smaller land share.</li>
          <li><strong>Hotels & Medical</strong> land uses are generally the most revenue generating when examining on a tax/acre basis</li>
        </ul>

      </div>

    </div>
  );

};

export default TaxPerAcreTableau;
