/**
 * Converts a date into a human-readable string that respects browser locale
 * settings. If `includeTime` is true (which is its default value), the time
 * will be added to the end.
 *
 * @returns A string that features the date and the time separated by a space.
 */
export const getHumanReadableDate = (dt: Date, includeTime: boolean = true): string => {
  return `${dt.toLocaleDateString()}${includeTime ? ' ' + dt.toLocaleTimeString() : ''}`;
};

/**
 * Some users disable excessive motion, such as automated scrolling, which can
 * cause motion sickness or other problems.
 *
 * Use this to determine if the user prefers reduced motion.
 *
 * @returns `true` if the user prefers reduced motion.
 */
export const prefersReducedMotion = (): boolean => {
  return window.matchMedia('(prefers-reduced-motion: reduce)').matches;
};

/**
 * Causes the current page to change.
 *
 * @param url The URL to navigate to.
 */
export const navigateTo = (url: string) => {
  window.location.href = url;
};
