import React from 'react';
import SimpleCentered from '../components/simple-centered';

const HomePage = () => {
  return (
    <main className="container w-full min-w-full">
      <SimpleCentered/>
    </main>
  );
};

export default HomePage;
