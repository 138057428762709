import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { HeaderProps } from './components/header';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import HomePage from './pages/home';
import NotFoundPage from './pages/not-found';
import Header from './components/header';
import Footer, { FooterProps } from './components/footer';
import EmailSvg from './svg/email';
import {
  legalPrivacyPolicyHref,
  legalAttributionsHref,
  legalTermsConditionsHref,
  contactHref,
  rootHref,
  leadershipHref,
  mediaKitHref,
  marketingTeamHref,
  meetingNotesHref,
  nextMeetingHref,
  pastEventsHref,
  rapidResponseHref,
  upcomingEventsHref,
  villagesHref,
  merchandiseHref,
  toolsHref,
  initiativesHref,
  steeleIndianSchoolParkHref,
  mapsHref,
  dataHref,
  toolTaxPerAcreHref,
  blogHref,
  inauguralBlogHref,
  mesaLightRailExpansionHref,
  globalInitiativesHref,
  crispHref,
  globalBlogHref,
  globalBlogContributeHref,
  globalBlogEventsHref,
  globalBlogMeetingNotesHref,
  globalBlogBuildItTheyWillComeHref,
  getInvolvedHref,
  podcastHref
} from './constants/constants';
import './index.css';
import DiscordSvg from './svg/discord';
import XTwitterSvg from './svg/x';
import InstagramSvg from './svg/instagram';
import TikTokSvg from './svg/tiktok';
import YouTubeSvg from './svg/youtube';
import FacebookSvg from './svg/facebook';
import ComingSoonPage from './pages/coming-soon';
import PrivacyPolicyPage from './pages/privacy';
import TaxPerAcreTableau from './pages/tools/data/tax-full';
import SimpleCentered from './components/simple-centered';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const ComingSoonElement = (
  <ComingSoonPage />
);


interface NavigateToProps {
  url: string;
}

const NavigateTo = (props: NavigateToProps) => {
  window.location.href = props.url;
  return (<p className="my-48 mx-20">Loading...</p>);
};

const router = createBrowserRouter([
  {
    path: rootHref,
    element: <HomePage />,
    errorElement: <NotFoundPage />,
  },

  // foundational pages
  { path: rapidResponseHref, element: ComingSoonElement },
  { path: villagesHref, element: ComingSoonElement },
  // { path: marketingTeamHref, element: ComingSoonElement },
  { path: leadershipHref, element: ComingSoonElement },
  { path: nextMeetingHref, element: <SimpleCentered  scrollToElement='part-4'/> },
  { path: upcomingEventsHref, element: <NavigateTo url={globalBlogEventsHref}></NavigateTo> },
  { path: pastEventsHref, element: <NavigateTo url={globalBlogEventsHref}></NavigateTo> },
  { path: meetingNotesHref, element: <NavigateTo url={globalBlogMeetingNotesHref}></NavigateTo> },
  { path: mediaKitHref, element: ComingSoonElement },
  { path: merchandiseHref, element: ComingSoonElement },
  { path: contactHref, element: ComingSoonElement },
  { path: legalAttributionsHref, element: ComingSoonElement },
  { path: legalPrivacyPolicyHref, element: <PrivacyPolicyPage /> },
  { path: legalTermsConditionsHref, element: ComingSoonElement },
  { path: toolsHref, element: ComingSoonElement },
  { path: mapsHref, element: ComingSoonElement },
  { path: dataHref, element: ComingSoonElement },

  // initiatives
  { path: initiativesHref, element: <NavigateTo url={globalInitiativesHref}></NavigateTo> },
  { path: steeleIndianSchoolParkHref, element: <NavigateTo url={crispHref}></NavigateTo> },

  // blog
  { path: blogHref, element: <NavigateTo url={globalBlogHref}></NavigateTo> },
  { path: mesaLightRailExpansionHref, element: <NavigateTo url={globalBlogBuildItTheyWillComeHref}></NavigateTo> },
  { path: inauguralBlogHref, element: <NavigateTo url={globalBlogContributeHref}></NavigateTo> },

  // tools
  { path: toolTaxPerAcreHref, element: <TaxPerAcreTableau /> },

]);

const headerProps: HeaderProps = {
  companyTagLine: 'Strong Towns Phoenix',
  companyText: 'Strong Towns Phoenix',
  navigation: [
    { name: 'Home', href: rootHref },
    // { name: 'Tools', href: toolsHref },
    // { name: 'About', href: aboutHref },
    // { name: 'Contact', href: contactHref },
  ],
};

/** This is when we founded Strong Towns and is mainly used for copyright. */
const initialYear = 2024;
const now = new Date();
const nowYear = now.getFullYear();

/**
 * Returns either be a string such as `2024` or a string of the form
 * `2023-2024`.
 */
let copyrightYear: string = `${initialYear}-${nowYear}`;
if (initialYear === nowYear) {
  copyrightYear = `${nowYear}`;
}

const footerProps: FooterProps = {
  linkColumns: [
    {
      headerText: 'Get involved',
      links: [
        { text: 'Sign up', href: 'https://signup.strongtownsphx.org'},
        // { text: 'Leadership', href: leadershipHref },
        { text: 'Marketing Team', href: marketingTeamHref },
        { text: 'Advocacy', href: getInvolvedHref },
        { text: 'Volunteer Opportunities', href: getInvolvedHref },
        { text: 'Strong Towns', href: 'https://strongtowns.org' },
      ]
    },
    {
      headerText: 'Resources',
      links: [
        { text: 'Blog', href: globalBlogHref },
        { text: 'Podcast', href: podcastHref },
        // { text: 'Meeting notes', href: meetingNotesHref },
        // { text: 'Tools', href: toolsHref },
        // { text: 'Maps', href: mapsHref },
        // { text: 'Data', href: dataHref },
        // { text: 'Tax Per Acre', href: toolTaxPerAcreHref },
        { text: 'Media and press kit', href: mediaKitHref },
        // { text: 'Merchandise', href: merchandiseHref },
      ]
    },
    {
      headerText: 'Events',
      links: [
        { text: 'Initiatives', href: globalInitiativesHref },
        { text: 'Next meeting', href: nextMeetingHref },
        { text: 'Events', href: globalBlogEventsHref },
        // { text: 'Past events', href: globalBlogEventsHref },
      ]
    },
    {
      headerText: 'Legal',
      links: [
        { text: 'Privacy Policy', href: legalPrivacyPolicyHref },
        // { text: 'Attributions', href: legalAttributionsHref },
        // { text: 'Terms & Conditions', href: legalTermsConditionsHref },
      ]
    },
  ],
  bottomLeftContent: <>
    © {copyrightYear} <a href="https://strongtownsphx.org/">Strong Towns Phoenix</a>. All Rights Reserved.
  </>,
  socials: [
    {
      link: 'https://www.tiktok.com/@strongtownsphx/',
      svg: <TikTokSvg className='social-icon text-black dark:text-white' />,
      text: 'TikTok',
    },
    {
      link: 'https://www.facebook.com/people/Stong-Towns-Phoenix/61556441207605/',
      svg: <FacebookSvg className='social-icon text-black dark:text-white' />,
      text: 'Facebook',
    },
    {
      link: 'https://www.instagram.com/strongtownsphx',
      svg: <InstagramSvg className='social-icon text-black dark:text-white' />,
      text: 'Instagram',
    },
    {
      link: 'https://youtube.com/@strongtownsphx',
      svg: <YouTubeSvg className='social-icon text-black dark:text-white' />,
      text: 'YouTube',
    },
    {
      link: 'https://twitter.com/strongtownsphx',
      svg: <XTwitterSvg className='social-icon text-black dark:text-white' />,
      text: 'X / Twitter',
    },
    {
      link: 'https://discord.strongtownsphx.org',
      svg: <DiscordSvg className='social-icon text-black dark:text-white' />,
      text: 'Discord',
    },
    {
      link: 'mailto:contact@strongtownsphx.org',
      svg: <EmailSvg className='social-icon text-black dark:text-white' />,
      text: 'Email',
    }
  ],
};


root.render(
  <React.StrictMode>
    <Header {...headerProps}></Header>
    <RouterProvider router={router} />
    {/* <AdblockRecovery/> */}
    <Footer {...footerProps}></Footer>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
