import React from 'react';

const PrivacyPolicyPage = () => {
  return (
    <main className="container mx-auto pt-20 px-4">
      <div className="mb-4">
        <h1 className="text-3xl font-bold mb-4 text-center dark:text-white">Privacy Policy</h1>
        <p className="mb-4">
            Thank you for visiting our website. Your privacy is important to us, and we want you to feel comfortable while browsing our site. This Privacy Policy outlines how we collect and locally store basic data about your browser and internet connection.
        </p>
      </div>
      <div className="mb-4 border-l-4 border-l-light-blue-900 pl-4">
        <p className="mb-4">
          <u>Effective Date: May 1st, 2024</u>
        </p>
        <h3 className="text-xl font-bold mb-4">Information We Collect:</h3>
        <p className="mb-4">
            When you visit our website, we may collect basic data about your browser and internet connection. This information may include your browser type, device type, operating system, IP address, and internet service provider.
        </p>
        <h3 className="text-xl font-bold mb-4">Cookies:</h3>
        <p className="mb-4">
            We do not use or store any cookies in your browser when you visit any page on this site.
        </p>
        <h3 className="text-xl font-bold mb-4">Use of Information:</h3>
        <p className="mb-4">
            We collect this basic data to improve the functionality of our website and to enhance your browsing experience. We do not share this information with <b>any</b> third parties and only store it locally on our servers. We do not sell this data to anyone.
        </p>
        <h3 className="text-xl font-bold mb-4">Updates to Privacy Policy:</h3>
        <p className="mb-4">
            We reserve the right to update this Privacy Policy in the future to reflect any changes in our data collection practices. We encourage you to review this Privacy Policy periodically for any updates.
        </p>
        <h3 className="text-xl font-bold mb-4">Contact Us:</h3>
        <p className="mb-4">
            If you have any questions or concerns about our Privacy Policy or data collection practices, please contact us at <a href="mailto:contact@strongtownsphx.org"><u>contact@strongtownsphx.org</u></a>.
        </p>
        <p className="mb-4">
            By continuing to use our website, you agree to the terms outlined in this Privacy Policy.
        </p>
      </div>
    </main>
  );
};

export default PrivacyPolicyPage;

