import React from 'react';
// import useWindowSize from 'react-use/lib/useWindowSize';
import {
  MapContainer,
  MapContainerProps,
  Marker,
  Tooltip,
  TileLayer,
  Polygon,
  Popup,
  // GeoJSON,
  // Popup,
} from 'react-leaflet';
import villages from '../resources/villages.json';
import type { LatLngTuple } from 'leaflet';
import uniqolor from 'uniqolor';
// import type { Feature } from 'geojson';

export interface MapCenteredProps {
  mapContainerProps?: MapContainerProps;
  style?: React.CSSProperties;
}

const MapCentered = (props: MapCenteredProps) => {
  const {
    mapContainerProps,
    style,
  } = props;

  // leaving this code here for future reference. Sorry for the mess.

  // const [villages, setVillages] = useState<Feature[]>();

  // useEffect(() => {
  //   const f = async () => {
  //     if (villages) return;
  //     try {
  //       const response = await fetch('/geojson/phx-villages_2024-03-18.geojson');
  //       const layer = await response.json();
  //       if (!layer.features) return;
  //       setVillages(layer.features as Feature[]);
  //     } catch (e) {
  //       console.error('failed to fetch village geojson', e);
  //     }
  //   };
  //   f();
  // }, []);

  // const { width, height } = useWindowSize();

  // const map = useMap();

  // console.log(map.getCenter());

  // const villagesLayer = villages ? villages.map((f, i) => (<GeoJSON key={`gjlf-${i}`} interactive data={f} onEachFeature={(f, l) => {
  //   l.addEventListener('click', () => {
  //     alert(JSON.stringify(f.properties));
  //   });
  // }} />)): <></>;

  const villagesLayers: JSX.Element[] = [];

  if (villages) {
    for (let i = 0; i < villages.features.length; i++) {
      if (villages.features[i].geometry.type === 'MultiPolygon') {
        // for a multi-polygon, it is an array of polygons:
        // [ Polygon, Polygon, ... ]
        //
        // and a polygon is an array of lat/long arrays:
        //
        // Polygon = [ [lat, lng ], ... ]

        for (let j = 0; j < villages.features[i].geometry.coordinates.length; j++) {
          for (let k = 0; k < villages.features[i].geometry.coordinates[j].length; k++) {
            const polygonCoords = villages.features[i].geometry.coordinates[j][k];
            const polygonCoordsLatLng: LatLngTuple[] = [];
            for (let l = 0; l < polygonCoords.length; l++) {
              if (polygonCoords[l].length !== 2) {
                console.log(`${i} ${j} ${k} lacks exactly two coordinates`);
                continue;
              }

              // Also, for some reason, when I export the geojson from qgis, the
              // lat/lng are flipped, so reverse them here.
              const fixedPolygonCoords = [...polygonCoords[l]];
              fixedPolygonCoords.reverse();

              // typescript is having a really hard time inferring the type so
              // we're just going to override it. I've already done the type
              // checking up to this point in the code, and... it checks out.
              polygonCoordsLatLng.push(fixedPolygonCoords as LatLngTuple);
            }

            villagesLayers.push(
              <Polygon
                key={`village-poly-${i}-${j}-${k}`}
                pathOptions={{
                  color: uniqolor(villages.features[i].properties.NAME).color,
                  fill: true,
                  fillOpacity: 0.25,
                  dashArray: '5',
                  stroke: true,
                  weight: 2,
                }}
                positions={polygonCoordsLatLng}>
                <Tooltip>Phoenix Village: {villages.features[i].properties.NAME}</Tooltip>
              </Polygon>
            );
          }
        }
      }
    }
  }

  return (
    <MapContainer center={[33.4625,-112.0732]} zoom={13} style={style} scrollWheelZoom={true} {...mapContainerProps}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker alt='Burton Barr Central Library in Phoenix is our primary meeting location' position={[33.4625,-112.0732]} draggable={false} riseOnHover>
        <Popup keepInView>
          This is the location of the <a href="https://www.phoenixpubliclibrary.org/locations/burton-barr" target='_blank' rel="noopener noreferrer nofollow"><b>Burton Barr Central Library</b></a>, which is where we meet monthly in Phoenix.
        </Popup>
      </Marker>
      {villagesLayers}
    </MapContainer>
  );
};

export default MapCentered;
